import React, { useState, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import moment, { Moment } from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import {
    Alert,
    Avatar,
    Badge,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormGroup,
    Grid,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { DatePicker, StaticDatePicker } from '@mui/lab';
import { useTheme, darken, lighten } from '@mui/material/styles';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

// MUI Grid
import { DataGridPro, GridRowId } from '@mui/x-data-grid-pro';
import { getPaymentsListColumns } from './grid-columns';

// MUI Icons
import {
    ArrowBackIos as ArrowBackIosIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
    CheckCircle as CheckCircleIcon,
    Done as DoneIcon,
} from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import DataGridLoader from 'components/DataGridLoader';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_MD_EARNINGS_GRAPH_DATA, GET_MD_PAYMENTS, GET_MD_EC_STATS } from './apollo-queries';

// Redux
import { useAuth } from 'providers/AuthProvider/context';

// Services
import { Api } from 'services';

// Constants
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';

// Utils
import { capitalize, formatNumber } from 'utils';
import { babillardItems } from './news/news';
import { LinearProgress } from '@mui/material';

const tableBorderColor = 'rgb(224, 224, 224)';
const tableTdBgHoverColor = 'rgba(0, 0, 0, 0.02)';
const tableTdOtherMonthBgColor = 'rgba(0, 0, 0, 0.04)';
// const tableTdOtherMonthBgHoverColor = 'rgba(0, 0, 0, 0.08)';

export default function Dashboard() {
    const theme = useTheme();
    const auth = useAuth();

    let categories: number[] = [];
    for (let i = 0; i < 12; i++) {
        categories.push(i + 1);
    }

    const [isPaymentInfoDialogOpen, setIsPaymentInfoDialogOpen] = useState(false);
    const handleOpenPaymentInfoDialog = () => {
        setIsPaymentInfoDialogOpen(true);
    };
    const handleClosePaymentInfoDialog = () => {
        setIsPaymentInfoDialogOpen(false);
    };

    const [period1, setPeriod1] = useState({
        start_date: moment().subtract(1, 'year').startOf('year'),
        end_date: moment().subtract(1, 'year').endOf('year'),
    });
    const [period2, setPeriod2] = useState({
        start_date: moment().startOf('year'),
        end_date: moment().endOf('year'),
    });

    // Apollo queries
    const {
        loading: mdEarningsGraphP1DataLoading,
        error: mdEarningsGraphP1DataError,
        data: mdEarningsGraphP1DataData,
    } = useQuery(GET_MD_EARNINGS_GRAPH_DATA, {
        variables: period1,
    });
    const {
        loading: mdEarningsGraphP2DataLoading,
        error: mdEarningsGraphP2DataError,
        data: mdEarningsGraphP2DataData,
    } = useQuery(GET_MD_EARNINGS_GRAPH_DATA, {
        variables: period2,
    });
    const {
        loading: ecStatsLoading,
        error: ecStatsError,
        data: ecStatsData,
    } = useQuery(GET_MD_EC_STATS, {
        variables: { which: 'current' },
    });
    const {
        loading: ecStatsPrevLoading,
        error: ecStatsPrevError,
        data: ecStatsPrevData,
    } = useQuery(GET_MD_EC_STATS, {
        variables: { which: 'previous' },
    });

    const { loading: mdPaymentsLoading, error: mdPaymentsError, data: mdPaymentsData } = useQuery(GET_MD_PAYMENTS, {});

    const currEc = ecStatsData?.mdEcStats || {};
    const prevEc = ecStatsPrevData?.mdEcStats || {};

    return (
        <>
            <Grid container spacing={2} alignItems="flex-start">
                <Grid item lg={9} xl={9.5} container spacing={2}>
                    {true && (
                        <Grid item xs={12}>
                            <Alert severity="info" elevation={1}>
                                <Typography variant="h6">Information importante</Typography>
                                <br />
                                {/* <Typography variant="body2" sx={{ lineHeight: 1.65 }}>
                                Vous pouvez désormais visualiser les réclamations de frais de déplacement, de vacation
                                et de rémunération mixte dans la page{' '}
                                <Link component={RouterLink} to="/demandes" fontWeight="bold">
                                    Recherche réclamations
                                </Link>
                                . Vous pouvez aussi visualiser le détaillé de ces réclamations en cliquant sur la flèche
                                bleue située à la droite de la colonne 'NCE' du tableau.
                            </Typography> */}

                                <Typography variant="body2" sx={{ lineHeight: 1.65 }}>
                                    Prendre note que nous avons déménagé notre place d’affaires au 5205 boulevard
                                    Grande-Allée, suite 200, Brossard, Québec, J4Z 3G5.
                                    <br />
                                    <br />
                                    Nous vous invitons à acheminer toutes correspondances à cette nouvelle adresse à
                                    compter de maintenant.
                                    {/* <br />
                                    <br />
                                    Merci de votre collaboration et au plaisir, */}
                                </Typography>
                            </Alert>
                        </Grid>
                    )}

                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12} mt={1}>
                            <Typography variant="h6" fontWeight={600}>
                                Statistiques de l'état de compte en cours
                                {!ecStatsLoading
                                    ? ` (${moment(currEc.dd_periode_fact).format('Do MMMM')} au ${moment(
                                          currEc.date_coupure,
                                      ).format('Do MMMM')})`
                                    : ''}
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <StatCard
                                title="Montant préliminaire"
                                value={`${formatNumber(currEc.total_prelim)} $`}
                                chipLabel={`EC-${ecStatsLoading ? '__' : currEc.no_paiement}`}
                                which={'current'}
                                loading={ecStatsLoading}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <StatCard
                                title="Réclamations envoyées"
                                value={currEc.facture_count}
                                chipLabel={`EC-${ecStatsLoading ? '__' : currEc.no_paiement}`}
                                which={'current'}
                                loading={ecStatsLoading}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <StatCard
                                title="Patients facturés"
                                value={currEc.patient_count}
                                chipLabel={`EC-${ecStatsLoading ? '__' : currEc.no_paiement}`}
                                which={'current'}
                                loading={ecStatsLoading}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <StatCard
                                title="Jours facturés"
                                value={currEc.days_billed}
                                chipLabel={`EC-${ecStatsLoading ? '__' : currEc.no_paiement}`}
                                which={'current'}
                                loading={ecStatsLoading}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12} mt={1}>
                            <Typography variant="h6" fontWeight={600}>
                                Statistiques du dernier état de compte
                                {/* {ecStatsPrevLoading
                                    ? ''
                                    : ` (${moment(prevEc.dd_periode_fact).format('Do MMMM')} au ${moment(
                                          prevEc.date_coupure,
                                      ).format('Do MMMM')}), paiement attendu le ${
                                          prevEc ? moment(prevEc.date_paiement).format('Do MMMM') : ''
                                      }`} */}
                                {ecStatsPrevLoading
                                    ? ''
                                    : ` (${moment(prevEc.dd_periode_fact).format('Do MMMM')} au ${moment(
                                          prevEc.date_coupure,
                                      ).format('Do MMMM')})`}
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <StatCard
                                title="Montant préliminaire"
                                value={`${formatNumber(prevEc.total_prelim || 0)} $`}
                                chipLabel={`EC-${ecStatsPrevLoading ? '__' : prevEc.no_paiement}`}
                                which={'previous'}
                                loading={ecStatsPrevLoading}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <StatCard
                                title="Réclamations envoyées"
                                value={prevEc.facture_count || 0}
                                chipLabel={`EC-${ecStatsPrevLoading ? '__' : prevEc.no_paiement}`}
                                which={'previous'}
                                loading={ecStatsPrevLoading}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <StatCard
                                title="Patients facturés"
                                value={prevEc.patient_count || 0}
                                chipLabel={`EC-${ecStatsPrevLoading ? '__' : prevEc.no_paiement}`}
                                which={'previous'}
                                loading={ecStatsPrevLoading}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <StatCard
                                title="Jours facturés"
                                value={prevEc.days_billed || 0}
                                chipLabel={`EC-${ecStatsPrevLoading ? '__' : prevEc.no_paiement}`}
                                which={'previous'}
                                loading={ecStatsPrevLoading}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}></Grid>

                    {/* <Grid item xs={12} lg={4}>
                        <Babillard />
                    </Grid> */}

                    <Grid item xs={12} lg={12}>
                        <Paper
                            sx={{
                                px: 2,
                                py: 2,
                            }}
                            elevation={1}
                        >
                            <Typography variant="h6" mb={1}>
                                Comparaison des revenus par EC entre {period1.start_date.year()} et{' '}
                                {period2.start_date.year()}
                            </Typography>

                            <EarningsGraph
                                mdEarningsGraphData={[mdEarningsGraphP1DataData, mdEarningsGraphP2DataData]}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                <Grid item lg={3} xl={2.5} container spacing={2}>
                    {/* <Grid item xs={12}>
                        <StatCard title="Solde à payer" value="320.45 $" main={true} />
                    </Grid> */}

                    <Grid
                        item
                        xs={12}
                        sx={(theme) => ({
                            [theme.breakpoints.down(1700)]: {
                                display: 'none',
                            },
                        })}
                    >
                        <Paper
                            sx={{
                                px: 0,
                                py: 0,
                                minWidth: 320,
                            }}
                            elevation={1}
                        >
                            <LittleCalendar />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                px: 2,
                                py: 2,
                            }}
                            elevation={1}
                        >
                            <Typography variant="h6" mb={2}>
                                Solde à payer
                            </Typography>

                            <Typography
                                variant="h4"
                                align="center"
                                fontWeight={600}
                                mt={2}
                                sx={{ color: theme.palette.primary.main }}
                            >
                                {formatNumber(auth.md!.balance / 100)} $
                            </Typography>

                            {/* <Typography variant="caption" mb={2} align="center" sx={{ display: 'block', mt: 1 }}>
                                ** Aucune mise à jour pour les sommes reçues et aucune facture émise par Facturation
                                médicale Paradis depuis le 10 juin. La mise à jour se fera d’ici samedi le 23 juillet
                                prochain. Merci de patienter. **
                            </Typography> */}

                            <Button
                                variant="text"
                                sx={{ display: 'block', mx: 'auto', mt: 1 }}
                                onClick={handleOpenPaymentInfoDialog}
                                color="secondary"
                            >
                                Afficher infos paiement
                            </Button>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper elevation={1}>
                            <Box
                                sx={{
                                    px: 2,
                                    py: 2,
                                    pb: 0,
                                }}
                            >
                                <Typography variant="h6" mb={2}>
                                    Derniers paiements
                                </Typography>
                            </Box>

                            <PaymentsTable />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

            <PaymentInfoDialog
                isPaymentInfoDialogOpen={isPaymentInfoDialogOpen}
                handleClosePaymentInfoDialog={handleClosePaymentInfoDialog}
            />
        </>
    );
}

interface StartCardProps {
    title: string;
    value: string;
    chipLabel?: string;
    main?: boolean;
    which: string;
    loading: boolean;
}
function StatCard(props: StartCardProps) {
    const { title, value, chipLabel, main, which, loading } = props;

    return (
        <Paper
            sx={{
                px: 2,
                py: 2,
                backgroundColor: main ? 'primary.main' : '',
                color: main ? 'primary.contrastText' : '',
            }}
            elevation={1}
        >
            <Grid container justifyContent="space-between" pb={1}>
                {/* <Grid item xs={chipLabel ? 8 : 12}> */}
                <Grid item xs={12} xl={9}>
                    <Typography variant="h6">{title}</Typography>
                </Grid>
                {chipLabel && (
                    <Grid
                        item
                        xs={12}
                        xl={3}
                        container
                        sx={(theme) => ({
                            [theme.breakpoints.up('xl')]: {
                                mt: -1,
                                mr: -1,
                                justifyContent: 'flex-end',
                            },
                            [theme.breakpoints.down('xl')]: {
                                mt: 0.7,
                                mb: 1,
                                justifyContent: 'flex-start',
                            },
                        })}
                    >
                        <Chip
                            label={chipLabel}
                            color={which === 'current' ? 'primary' : 'secondary'}
                            size="small"
                            sx={{
                                fontSize: 12,
                                height: '22px',
                                borderRadius: 1,
                            }}
                        />
                    </Grid>
                )}
            </Grid>

            {!loading ? <Typography variant="h5">{value}</Typography> : <LinearProgress sx={{ mt: 2.5 }} />}
        </Paper>
    );
}

interface EarningsGraphProps {
    mdEarningsGraphData: any[];
}
const earningsGraphOptions: ApexOptions = {
    chart: {
        toolbar: { show: false },
        events: {
            mounted: (chart) => {
                chart.windowResizeHandler();
            },
        },
    },
    stroke: {
        curve: 'smooth',
        width: 3,
    },
    xaxis: {
        categories: [],
    },
    legend: {
        formatter: function (serieName, { seriesIndex }) {
            switch (seriesIndex) {
                case 0:
                    return `${moment().subtract(1, 'year').year()}`;
                case 1:
                    return `Cumulatif ${moment().subtract(1, 'year').year()}`;
                case 2:
                    return `${moment().year()}`;
                case 3:
                    return `Cumulatif ${moment().year()}`;
                default:
                    return '';
            }
        },
    },
    yaxis: [
        {
            // title: {
            //     text: "Montant net de l'EC",
            // },
            seriesName: 'normal-serie',
            labels: {
                formatter: function (value) {
                    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
                    // // example 1
                    // let million = formatter.format(1e6);
                    // // example 2
                    // let billion = formatter.format(1e9);
                    // // print
                    // console.log(million == '1M', billion == '1B');
                    return formatter.format(value);
                },
            },
        },
        {
            seriesName: 'cum-serie',
            opposite: true,
            // title: {
            //     text: 'Cumulatif',
            // },
            labels: {
                formatter: function (value) {
                    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
                    // // example 1
                    // let million = formatter.format(1e6);
                    // // example 2
                    // let billion = formatter.format(1e9);
                    // // print
                    // console.log(million == '1M', billion == '1B');
                    return formatter.format(value);
                },
            },
        },
        {
            seriesName: 'normal-serie',
            show: false,
            labels: {
                formatter: function (value) {
                    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
                    // // example 1
                    // let million = formatter.format(1e6);
                    // // example 2
                    // let billion = formatter.format(1e9);
                    // // print
                    // console.log(million == '1M', billion == '1B');
                    return formatter.format(value);
                },
            },
        },
        {
            seriesName: 'cum-serie',
            show: false,
            labels: {
                formatter: function (value) {
                    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
                    // // example 1
                    // let million = formatter.format(1e6);
                    // // example 2
                    // let billion = formatter.format(1e9);
                    // // print
                    // console.log(million == '1M', billion == '1B');
                    return formatter.format(value);
                },
            },
        },
    ],
    tooltip: {
        x: {
            formatter: (val, opts) => {
                return `EC-${val * 2}`;
            },
        },
        y: {
            // formatter: (val) => {
            //     return `${val}`;
            // },
            title: {
                formatter: (val) => {
                    // console.log(val);
                    return `: `;
                },
            },
        },
    },
    fill: {
        // type: 'gradient',
        // gradient: {
        //     shadeIntensity: 1,
        //     opacityFrom: 0.7,
        //     opacityTo: 0.9,
        //     stops: [0, 90, 100],
        // },
    },
};
function EarningsGraph(props: EarningsGraphProps) {
    const theme = useTheme();
    const { mdEarningsGraphData } = props;

    let shouldRender = false;
    shouldRender = mdEarningsGraphData.every((query) => {
        return query ? (query.mdEarningsGraphData ? true : false) : false;
    });

    if (shouldRender) {
        const firstSerie = mdEarningsGraphData[0].mdEarningsGraphData;

        const categories = firstSerie.map((item) => `${item.label}`);
        earningsGraphOptions.xaxis!.categories = categories;

        let series: ApexAxisChartSeries = [];
        mdEarningsGraphData.map((query, serieIdx) => {
            const data = query.mdEarningsGraphData;

            const years: number[] = [];

            const values: (number | null)[] = data.map((item, idx) => {
                const { label, date_ec, value } = item;

                if (idx === 0 || idx === data.length - 1) {
                    const year = moment(date_ec).year();

                    if (!years.includes(year)) years.push(year);
                }

                return value || 0;
            });
            while (values.length < 26) {
                values.push(null);
            }

            const cums: (number | null)[] = [];
            values.forEach((value) => {
                const lastCum = cums.length > 0 ? cums[cums.length - 1] : 0;
                if (lastCum === null || value === null) {
                    cums.push(null);
                } else {
                    cums.push(value + lastCum);
                }
            });

            const serieName = years.sort((a, b) => a - b).join('-');

            series.push({
                // name: `ORs ${serieName}`,
                name: `normal-serie`,
                type: 'bar',
                data: values,
                color: serieIdx ? theme.palette.primary.main : theme.palette.secondary.main,
            });

            series.push({
                // name: `Cumulatif ${serieName}`,
                name: `cum-serie`,
                type: 'line',
                data: cums,
                color: serieIdx ? darken(theme.palette.primary.main, 0.2) : darken(theme.palette.secondary.main, 0.2),
            });
        });

        return <Chart options={earningsGraphOptions} series={series} height={425} />;
    } else {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" pt={4} pb={2}>
                <Loading />
            </Box>
        );
    }
}

// function PaymentsTable() {
//     const [pageSize, setPageSize] = React.useState<number>(5);

//     // Apollo queries
//     const { loading: mdPaymentsLoading, error: mdPaymentsError, data: mdPaymentsData } = useQuery(GET_MD_PAYMENTS, {});

//     // Columns
//     const columns = useMemo(() => getPaymentsListColumns(), []);

//     return (
//         <DataGridPro
//             getRowId={(row) => row.id}
//             rows={mdPaymentsData?.mdPayments || []}
//             columns={columns}
//             pagination
//             pageSize={pageSize}
//             // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//             // rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
//             autoHeight
//             disableSelectionOnClick
//             loading={mdPaymentsLoading}
//             components={{
//                 LoadingOverlay: DataGridLoader,
//             }}
//             sx={{
//                 border: 0,
//                 '& .MuiDataGrid-cell:hover': {
//                     color: 'primary.main',
//                 },
//             }}
//         />
//     );
// }

function PaymentsTable() {
    const [pageSize, setPageSize] = React.useState<number>(5);

    const paymentsToShowIncrementer = 5;
    const [paymentsToShow, setPaymentsToShow] = useState(paymentsToShowIncrementer);

    // Apollo queries
    const { loading: mdPaymentsLoading, error: mdPaymentsError, data: mdPaymentsData } = useQuery(GET_MD_PAYMENTS, {});

    // Columns
    const columns = useMemo(() => getPaymentsListColumns(), []);

    const payments = mdPaymentsData?.mdPayments || [];

    return (
        <>
            <List dense disablePadding>
                {payments.slice(0, paymentsToShow).map((payment) => {
                    const { id, txn_recorded_at, METHOD_NAME, amt } = payment;

                    const primary = `Paiement #${id}`;
                    const secondary = `${moment(txn_recorded_at).format('LL')}`;

                    return (
                        <ListItemButton key={id}>
                            <ListItemAvatar>
                                <Avatar
                                    sx={(theme) => ({
                                        bgcolor: lighten(theme.palette.primary.main, 0.8),
                                        width: 35,
                                        height: 35,
                                    })}
                                >
                                    <DoneIcon color="primary" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={primary}
                                secondary={secondary}
                                secondaryTypographyProps={{ fontSize: 12, mt: 1 }}
                            />
                            <Typography variant="body2" fontWeight={700}>
                                {formatNumber(amt / 100)} $
                            </Typography>
                        </ListItemButton>
                    );
                })}
            </List>
            {payments.length > paymentsToShow && (
                <Box
                    sx={{
                        px: 2,
                        py: 2,
                        pt: 0,
                    }}
                >
                    <Button
                        variant="text"
                        fullWidth
                        onClick={() => setPaymentsToShow(paymentsToShow + paymentsToShowIncrementer)}
                    >
                        Voir plus
                    </Button>
                </Box>
            )}
        </>
    );
}

function LittleCalendar() {
    const [date, setDate] = React.useState<Moment | null>(moment());

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <CalendarPicker
                date={date}
                onChange={(newDate) => setDate(newDate)}
                minDate={moment().year(2016)}
                maxDate={moment().year(moment().year() + 2)}
                views={['day']}
            />
        </LocalizationProvider>
    );
}

interface PaymentInfoDialogProps {
    isPaymentInfoDialogOpen: boolean;
    handleClosePaymentInfoDialog: () => void;
}
function PaymentInfoDialog(props: PaymentInfoDialogProps) {
    const { isPaymentInfoDialogOpen, handleClosePaymentInfoDialog } = props;

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={isPaymentInfoDialogOpen} onClose={handleClosePaymentInfoDialog}>
            <DialogTitle variant="h5">Comment nous faire parvenir un paiement</DialogTitle>

            <DialogContent dividers>
                <Typography variant="h6" mb={1}>
                    Par transfert Interac:
                </Typography>
                <Box pl={2}>
                    <Typography variant="body1" mt={1}>
                        - Destinataire: paiement@fmparadis.com
                    </Typography>
                    <Typography variant="body1" mt={1}>
                        - Question secrète: Nom entreprise
                    </Typography>
                    <Typography variant="body1" mt={1}>
                        - Mot de passe: fmparadis
                    </Typography>
                </Box>

                <Typography variant="h6" mb={1} mt={4}>
                    Par transfert bancaire:
                </Typography>
                <Box pl={2}>
                    <Typography variant="body1" mt={1}>
                        - Numéro de transit: 30435
                    </Typography>
                    <Typography variant="body1" mt={1}>
                        - Numéro d'institution: 815
                    </Typography>
                    <Typography variant="body1" mt={1}>
                        - Numéro de compte: 2030161
                    </Typography>
                    <Typography variant="body1" mt={1}>
                        - Courriel de confirmation de paiement: paiement@fmparadis.com
                    </Typography>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClosePaymentInfoDialog}>Fermer</Button>
            </DialogActions>
        </Dialog>
    );
}

const txtMaxLength = 100;
const truncateTxt = (string) => (string.length > txtMaxLength ? `${string.substring(0, txtMaxLength)}...` : string);

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(firstName: string, lastName: string) {
    return {
        sx: {
            bgcolor: stringToColor(firstName + lastName),
            width: 35,
            height: 35,
        },
        children: `${firstName[0]}${lastName[0]}`,
    };
}

function Babillard() {
    const babillardItemsToShowIncrementer = 5;
    const [babillardItemsToShow, setBabillardItemsToShow] = useState(babillardItemsToShowIncrementer);

    const [isNewsInfoDialogOpen, setIsNewsInfoDialogOpen] = useState(false);
    const handleOpenNewsInfoDialog = (babillardItem) => {
        setIsNewsInfoDialogOpen(true);
        setSelectedBabillardItem(babillardItem);
    };
    const handleCloseNewsInfoDialog = () => {
        setIsNewsInfoDialogOpen(false);
    };
    const [selectedBabillardItem, setSelectedBabillardItem] = useState(null);

    // Apollo queries
    const { loading: mdPaymentsLoading, error: mdPaymentsError, data: mdPaymentsData } = useQuery(GET_MD_PAYMENTS, {});

    return (
        <Paper elevation={1}>
            <Box
                sx={{
                    px: 2,
                    pt: 2,
                }}
            >
                <Typography variant="h6" mb={1}>
                    Dernières nouvelles
                </Typography>
            </Box>

            <List dense disablePadding sx={{ pb: 1 }}>
                {babillardItems
                    .sort((a, b) => b.sent_on.unix() - a.sent_on.unix())
                    .slice(0, babillardItemsToShow)
                    .map((babillardItem, idx) => {
                        const { id, sent_on, admin, title, subtitle, content } = babillardItem;
                        const { first_name, last_name } = admin;

                        // const secondary = `${moment(txn_recorded_at).format('LL')}`;

                        return (
                            <Box key={idx}>
                                <ListItemButton
                                    alignItems="flex-start"
                                    onClick={() => handleOpenNewsInfoDialog(babillardItem)}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            {...stringAvatar(first_name, last_name)}
                                            // sx={(theme) => ({
                                            //     width: 35,
                                            //     height: 35,
                                            // })}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={title}
                                        secondary={
                                            <>
                                                <Typography variant="subtitle2" mb={1}>
                                                    {truncateTxt(subtitle)}
                                                </Typography>
                                                <Typography variant="caption">{`${first_name.substring(
                                                    0,
                                                    1,
                                                )}. ${last_name} · ${moment(sent_on).fromNow()}`}</Typography>
                                            </>
                                        }
                                        primaryTypographyProps={{ fontSize: 13, fontWeight: 600 }}
                                        secondaryTypographyProps={{ fontSize: 12, mt: 1, component: 'div' }}
                                    />
                                </ListItemButton>
                                {idx + 1 < babillardItems.length && <Divider variant="inset" component="li" />}
                            </Box>
                        );
                    })}
            </List>

            {babillardItems.length > babillardItemsToShow && (
                <Box
                    sx={{
                        px: 2,
                        pb: 2,
                    }}
                >
                    <Button
                        variant="text"
                        fullWidth
                        onClick={() => setBabillardItemsToShow(babillardItemsToShow + babillardItemsToShowIncrementer)}
                    >
                        Voir plus
                    </Button>
                </Box>
            )}

            <NewsInfoDialog
                isNewsInfoDialogOpen={isNewsInfoDialogOpen}
                handleCloseNewsInfoDialog={handleCloseNewsInfoDialog}
                selectedBabillardItem={selectedBabillardItem}
            />
        </Paper>
    );
}

interface NewsInfoDialogProps {
    isNewsInfoDialogOpen: boolean;
    handleCloseNewsInfoDialog: () => void;
    selectedBabillardItem: any;
}
function NewsInfoDialog(props: NewsInfoDialogProps) {
    const { isNewsInfoDialogOpen, handleCloseNewsInfoDialog, selectedBabillardItem } = props;

    if (isNewsInfoDialogOpen) {
        const { title, content } = selectedBabillardItem;

        return (
            <Dialog fullWidth={true} maxWidth={'md'} open={isNewsInfoDialogOpen} onClose={handleCloseNewsInfoDialog}>
                <DialogTitle variant="h5">{title}</DialogTitle>

                <DialogContent dividers>{content()}</DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseNewsInfoDialog}>Fermer</Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return <></>;
    }
}
